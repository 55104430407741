<template>
  <div :class="avatarClasses">
    <div :class="avatarImgClasses">
      <img v-if="src" :src="src" :alt="alt" />
      <slot v-else>{{ label }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BmAvatar',
  props: {
    label: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    avatarClasses() {
      return [
        'avatar',
        this.bordered ? 'avatar_bordered' : '',
        this.size ? `avatar_${this.size}` : '',
      ]
    },
    avatarImgClasses() {
      return [
        'avatar__img',
        'bg-blue',
        'text-white',
        this.color ? `text-${this.color}` : '',
        this.bgColor ? `bg-${this.bgColor}` : '',
      ]
    },
  },
}
</script>
