import api from '@src/api'

export default {
  async fetchAll({commit}) {
    const projects = await api.projects.all()
    commit('setProjects', projects)
    return projects
  },
  async fetchWorkload(context, {startDate, endDate}) {
    const workload = await api.projects.workload(startDate, endDate)
    context.commit('setWorkload', workload)
    return workload
  },
  async fetchProject({commit}, projectId) {
    return await api.projects.get(projectId)
  },
  changeCurrentTabKey({commit}, key) {
    commit('setCurrentTabKey', key)
  },
}
