<template>
  <BmIcon v-bind="$props" v-slot:default="{strokeClass}">
    <path
      d="M6 12L10 8L6 4"
      :class="strokeClass"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconBmIconChevronRight',
  props: {
    bgColor: {
      type: String,
      default: 'grey-dark',
    },
    color: {
      type: String,
      default: 'grey-dark',
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
  },
  computed: {
    classes() {
      return [this.color ? `text-${this.color}` : '']
    },
  },
}
</script>
