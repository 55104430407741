<template>
  <BmIcon v-bind="$props" v-slot:default="{fillClass}">
    <path
      d="M11.805 2.69536C12.065 2.43539 12.065 2.00212 11.805 1.75549L10.2452 0.195705C9.99861 -0.0642586 9.56534 -0.0642586 9.30537 0.195705L8.07888 1.41554L10.5785 3.91519L11.805 2.69536ZM0.999861 8.50122L0.247215 11.1355C0.139465 11.5126 0.488128 11.8613 0.865251 11.7535L3.49951 11.0009L9.18026 5.32012L6.66489 2.83619L0.999861 8.50122Z"
      :class="fillClass"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconPencil',
  props: {
    color: {
      type: String,
      default: 'grey-dark',
    },
    bgColor: {
      type: String,
      default: 'grey-dark',
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
  },
}
</script>
