export default {
  setCurrentUser(state, user) {
    state.currentUser = user
  },
  setUsers(state, users) {
    state.allUsers = users
  },
  setWorkload(state, workload) {
    state.workload = workload
  },
  setFilterWeeks(state, filterWeeks) {
    state.filterWeeks = filterWeeks
  },
}
