<template>
  <div :class="classes">
    <slot>{{ label }}</slot>
  </div>
</template>

<script>
export default {
  name: 'BmBadge',
  props: {
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: '',
    },
    uppercase: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classes() {
      return [
        'badge',
        this.uppercase ? `text-uppercase` : '',
        this.color ? `text-${this.color}` : '',
        this.bgColor ? `bg-${this.bgColor}` : '',
      ]
    },
  },
}
</script>
