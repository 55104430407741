<template>
  <BmIcon v-bind="$props" v-slot:default="{fillClass}">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 0C1.34315 0 0 1.34315 0 3V9C0 10.6569 1.34315 12 3 12H9C10.6569 12 12 10.6569 12 9V3C12 1.34315 10.6569 0 9 0H3ZM2 3C2 2.44772 2.44772 2 3 2H9C9.55229 2 10 2.44772 10 3V4H2V3ZM2 6V9C2 9.55229 2.44772 10 3 10H9C9.55229 10 10 9.55229 10 9V6H2Z"
      :class="fillClass"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconCalendar',
  props: {
    bgColor: {
      type: String,
      default: 'grey-dark',
    },
    color: {
      type: String,
      default: 'grey-dark',
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
  },
}
</script>
