import api from '@/api'

export default {
  async fetchStage({commit}, stageId) {
    const stage = await api.stages.get(stageId)
    commit('setCurrentStage', stage)
    return stage
  },

  async syncPositions(context, {stageId, positionIds}) {
    return await api.stages.syncPositions(stageId, {ids: positionIds})
  },

  async syncUsers(context, {stageId, users}) {
    return await api.stages.syncUsers(stageId, {
      ids: users.map((users) => users['id']),
    })
  },

  async syncScheduledTimes(context, {stageId, scheduledTimes}) {
    return await api.stages.syncScheduledTimes(stageId, {
      data: scheduledTimes,
    })
  },
}
