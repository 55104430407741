<template>
  <div class="switch">
    <div
      v-for="tab in tabs"
      :key="tab.key"
      :class="{switch__button_active: tab.key === currentTabKey}"
      class="switch__button"
      @click="changeCurrentTabKey(tab.key)"
    >
      {{ tab.label }}
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'BmTabs',

  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },

  methods: {
    ...mapActions({
      changeCurrentTabKey: 'projects/changeCurrentTabKey',
    }),
  },

  computed: mapState({
    currentTabKey: (state) => state.projects.currentTabKey,
  }),
}
</script>
