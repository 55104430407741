import api from '@/api'

export default {
  async fetchCurrent({commit}) {
    const user = await api.users.current()
    commit('setCurrentUser', user)
    return user
  },

  async fetchAll({commit}) {
    const users = await api.users.all()
    commit('setUsers', users)
    return users
  },

  async fetchWorkload(context, {startDate, endDate}) {
    const workload = await api.users.workload(startDate, endDate)
    context.commit('setWorkload', workload)
    return workload
  },
}
