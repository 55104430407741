<template>
  <BmIcon v-bind="$props" v-slot:default="{strokeClass}">
    <path
      d="M4 1L4 11"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      :class="strokeClass"
    />
    <path
      d="M4 0.999988C6.4 0.999987 10 0.500068 10 3.50007C10 6.50007 6 5.99959 4 5.99959"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      :class="strokeClass"
    />
    <path
      d="M2.5 9L7 9"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      :class="strokeClass"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconRuble',
  props: {
    color: {
      type: String,
      default: 'grey-dark',
    },
    bgColor: {
      type: String,
      default: 'grey-dark',
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
  },
}
</script>
