import api from './api.service'

export default {
  all() {
    return api.index('projects')
  },

  workload(startDate, endDate) {
    return api.show('projects', 'workload', {startDate, endDate})
  },

  create(data) {
    return api.store('projects', data)
  },

  get(projectId) {
    return api.show('projects', projectId)
  },

  update(projectId, data) {
    return api.update('projects', projectId, data)
  },

  delete(projectId) {
    return api.destroy('projects', projectId)
  },
}
