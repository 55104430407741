<template>
  <BmIcon v-bind="$props">
    <rect
      x="25.8994"
      y="3.27197"
      width="4"
      height="32"
      rx="1"
      transform="rotate(45 25.8994 3.27197)"
      fill="#768BA9"
    />
    <rect
      x="28.7278"
      y="25.8994"
      width="4"
      height="32"
      rx="1"
      transform="rotate(135 28.7278 25.8994)"
      fill="#768BA9"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconClose',
  props: {
    color: {
      type: String,
      default: 'white',
    },
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
  },
}
</script>
