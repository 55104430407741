import api from './api.service'

export default {
  all() {
    return api.index('units')
  },

  create(data) {
    return api.store('units', data)
  },

  get(unitId) {
    return api.show('units', unitId)
  },

  update(unitId, data) {
    return api.update('units', unitId, data)
  },

  delete(unitId) {
    return api.destroy('units', unitId)
  },
}
