<template>
  <BmIcon v-bind="$props">
    <path
      :fill="color"
      d="M5.76822 9.35981C5.41466 8.93553 4.78409 8.87821 4.35982 9.23177C3.93554 9.58534 3.87821 10.2159 4.23178 10.6402L5.76822 9.35981ZM8.125 13.75L7.35677 14.3902C7.55251 14.6251 7.84496 14.7575 8.15061 14.7497C8.45627 14.7418 8.74154 14.5946 8.925 14.35L8.125 13.75ZM14.55 6.85C14.8814 6.40817 14.7918 5.78137 14.35 5.45C13.9082 5.11863 13.2814 5.20817 12.95 5.65L14.55 6.85ZM4.23178 10.6402L7.35677 14.3902L8.89322 13.1098L5.76822 9.35981L4.23178 10.6402ZM8.925 14.35L14.55 6.85L12.95 5.65L7.325 13.15L8.925 14.35Z"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconCheck',
  props: {
    color: {
      type: String,
      default: 'black',
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
  },
}
</script>
