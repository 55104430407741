<template>
  <BmIcon v-bind="$props" v-slot:default="{fillClass}">
    <circle cx="2" cy="1.5" r="1.5" :class="fillClass" />
    <circle cx="2" cy="6" r="1.5" :class="fillClass" />
    <circle cx="2" cy="10.5" r="1.5" :class="fillClass" />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconMore',
  props: {
    bgColor: {
      type: String,
      default: 'ach-20',
    },
    color: {
      type: String,
      default: 'ach-20',
    },
    width: {
      type: [Number, String],
      default: 4,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
  },
}
</script>
