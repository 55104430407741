<template>
  <component
    :is="tag"
    :class="classes"
    v-on="$listeners"
    :disabled="disabled"
    @click="onClick"
  >
    <slot name="icon"></slot>
    <slot>{{ label }}</slot>

    <transition name="fade">
      <div
        v-if="dropdown"
        v-click-outside="closeDropdown"
        class="btn__dropdown"
        :class="{btn__dropdown__left: dropdownLeft}"
      >
        <slot name="dropdown"></slot>
      </div>
    </transition>
  </component>
</template>

<script>
export default {
  name: 'BmButton',
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    label: {
      type: [String, Number],
      default: '',
    },
    size: {
      type: String,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: '',
    },
    dropdownLeft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activating: false,
      dropdown: false,
    }
  },
  computed: {
    classes() {
      return [
        'btn',
        this.round ? 'btn_round' : '',
        this.rounded ? 'btn_rounded' : '',
        this.outlined ? 'btn_outlined' : '',
        this.flat ? 'btn_flat' : '',
        this.disabled ? 'btn_disabled' : '',
        this.size ? `btn_${this.size}` : '',
        this.color ? `text-${this.color}` : '',
        this.bgColor ? `bg-${this.bgColor}` : '',
      ]
    },
  },
  methods: {
    onClick() {
      if (this.$slots.dropdown) {
        this.toggleDropdown()
      }
    },

    closeDropdown() {
      if (this.activating) return

      this.dropdown = false
    },

    toggleDropdown() {
      this.activate()
      this.dropdown = !this.dropdown
    },

    activate() {
      this.activating = true
      setTimeout(() => {
        this.activating = false
      }, 100)
    },
  },
}
</script>
