<template>
  <svg
    :viewBox="viewBox"
    :width="width"
    :height="height"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <slot :strokeClass="strokeClass" :fillClass="fillClass"></slot>
  </svg>
</template>

<script>
export default {
  name: 'BmIcon',
  props: {
    bgColor: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    size: {
      type: String,
      default: '',
    },
  },
  computed: {
    viewBox() {
      return `0 0 ${this.width} ${this.height}`
    },
    fillClass() {
      return [this.bgColor ? `fill-${this.bgColor}` : '']
    },
    strokeClass() {
      return [this.color ? `stroke-${this.color}` : '']
    },
    classes() {
      return [this.color ? `text-${this.color}` : '']
    },
  },
}
</script>
