<template>
  <BmIcon v-bind="$props">
    <path
      d="M3.75 8C3.75 7.20728 3.96841 6.46468 4.34784 5.82884V3.13171H1.65072C0.58025 4.52196 0 6.21631 0 8C0 9.78368 0.58025 11.478 1.65072 12.8683H4.34784V10.1712C3.96841 9.53531 3.75 8.79271 3.75 8Z"
      fill="#FBBD00"
    />
    <path
      d="M8 12.2499L6.125 14.1249L8 15.9999C9.78372 15.9999 11.478 15.4196 12.8683 14.3492V11.6549H10.174C9.53262 12.0357 8.78691 12.2499 8 12.2499Z"
      fill="#0F9D58"
    />
    <path
      d="M4.34947 10.1711L1.65234 12.8683C1.86428 13.1435 2.09506 13.4071 2.34478 13.6569C3.85578 15.1678 5.86475 16 8.00163 16V12.25C6.45088 12.25 5.09172 11.415 4.34947 10.1711Z"
      fill="#31AA52"
    />
    <path
      d="M16 8.00013C16 7.51341 15.9559 7.02579 15.869 6.55085L15.7987 6.1665H8V9.91651H11.7954C11.4268 10.6497 10.8601 11.2478 10.174 11.6552L12.8682 14.3494C13.1435 14.1375 13.4071 13.9067 13.6568 13.657C15.1678 12.146 16 10.137 16 8.00013Z"
      fill="#3C79E6"
    />
    <path
      d="M11.0052 4.99478L11.3367 5.32622L13.9883 2.67459L13.6569 2.34316C12.1459 0.832156 10.1369 0 8 0L6.125 1.875L8 3.75C9.13519 3.75 10.2025 4.19206 11.0052 4.99478Z"
      fill="#CF2D48"
    />
    <path
      d="M8.00166 3.75V0C5.86478 0 3.85581 0.832156 2.34478 2.34313C2.09506 2.59284 1.86428 2.85644 1.65234 3.13172L4.34947 5.82884C5.09175 4.585 6.45091 3.75 8.00166 3.75Z"
      fill="#EB4132"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconGoogle',
  props: {
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
  },
}
</script>
