<template>
  <div class="sticky" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BmSticky',
  props: {
    position: {
      type: String,
      default: 'bottom-right',
    },
    offset: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    style() {
      const positions = this.position.split('-').reduce((acc, p) => {
        acc[p] = '0'

        return acc
      }, {})

      let offset = {}
      if (this.offset.length === 1) {
        offset.margin = this.offset[0] + 'px'
      } else if (this.offset.length === 2) {
        offset.marginTop = this.offset[0] + 'px'
        offset.marginBottom = this.offset[0] + 'px'
        offset.marginLeft = this.offset[1] + 'px'
        offset.marginRight = this.offset[1] + 'px'
      }

      return {
        ...positions,
        ...offset,
      }
    },
  },
}
</script>
<style lang="scss">
.sticky {
  position: fixed;
  z-index: 900;
}
</style>
