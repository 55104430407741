import Vue from 'vue'
import Vuex from 'vuex'
import positions from './positions'
import projects from './projects'
import stages from './stages'
import units from './units'
import users from './users'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    positions,
    projects,
    stages,
    units,
    users,
  },
})
