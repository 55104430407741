<template>
  <BmIcon v-bind="$props">
    <path
      d="M3.80457 10.3743L3.13278 5H8.86722L8.19543 10.3743C8.15075 10.7318 7.84689 11 7.48666 11H4.51334C4.15311 11 3.84925 10.7318 3.80457 10.3743Z"
      stroke="#E30613"
      stroke-width="2"
    />
    <rect
      x="1.42857"
      y="1.42857"
      width="9.14286"
      height="1.14286"
      rx="0.571429"
      fill="#E30613"
      stroke="#E30613"
      stroke-width="0.857143"
    />
    <path
      d="M3.5377 0.571429C3.61617 0.48375 3.73021 0.428571 3.85714 0.428571H8.14286C8.26979 0.428571 8.38383 0.48375 8.4623 0.571429H3.5377Z"
      fill="#C4C4C4"
      stroke="#E30613"
      stroke-width="0.857143"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconTrash',
  props: {
    color: {
      type: String,
      default: 'white',
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
  },
}
</script>
