<template>
  <BmIcon>
    <circle cx="12" cy="12" r="12" :fill="bgColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      :fill="color"
      d="M17.1958 14.0521C17.1958 14.0521 16.4921 14.8663 15.7444 14.8663C15.4365 14.8663 15.2823 14.6018 15.3925 14.1178L17.0198 6.59393C16.8222 6.44003 15.4142 6 13.6986 6C11.7629 6 9.89362 6.57178 8.24382 8.44187C6.52814 10.4001 6 12.5999 6 13.8976C6 15.5257 6.70389 17 8.37556 17C10.0474 17 12.0052 15.5257 13.3245 12.2041L13.3908 12.226C13.3908 12.226 12.423 14.5142 12.423 15.6356C12.423 16.2963 12.5986 17 13.6769 17C15.1286 17 16.7563 15.9001 17.702 14.5359L17.1958 14.0521ZM13.5433 9.34402C13.2354 10.7519 13.0594 11.3238 12.5536 12.3359C12.0476 13.3262 11.1901 14.5359 10.1122 14.5359C9.10019 14.5359 8.81458 13.8105 8.81458 12.7981C8.81458 10.5101 10.8605 7.09986 12.9058 7.09986C13.4339 7.09986 13.7637 7.20973 13.9616 7.45162L13.5433 9.34402Z"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconLogo',
  props: {
    color: {
      type: String,
      default: 'white',
    },
    bgColor: {
      type: String,
      default: 'black',
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
}
</script>
