<template>
  <label class="input" :class="classes">
    <textarea v-if="isTextArea" v-model="currentValue" class="input__field">
    </textarea>

    <input
      v-else
      v-model="currentValue"
      :type="type"
      class="input__field"
      @focus="focused = true"
      @blur="focused = false"
    />

    <span class="input__error-wrapper">
      <transition name="fade-slide-down">
        <div v-if="errorMessage" class="input__error-message">
          <BmIconAlert />
          {{ errorMessage }}
        </div>
      </transition>
    </span>

    <span class="input__placeholder">
      {{ label }}
    </span>

    <span class="input__append">
      <slot name="append"></slot>
    </span>
  </label>
</template>

<script>
const allowTypes = ['text', 'textarea']
export default {
  name: 'BmInput',
  props: {
    value: {
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return allowTypes.includes(value)
      },
    },
    rules: {
      type: Array,
      default: () => [],
    },
    completedTimeout: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      focused: false,
      isValid: true,
      errorMessage: '',
      timer: null,
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.value
      },
      set(value) {
        const self = this

        this.$emit('input', value)

        clearTimeout(this.timer)

        this.timer = setTimeout(function () {
          self.$emit('completed:input', value)
        }, this.completedTimeout)
      },
    },
    classes() {
      return {
        input_stacked: this.currentValue || this.focused,
        input_error: !this.isValid,
      }
    },

    isTextArea() {
      return this.type === 'textarea'
    },
  },
  methods: {
    validate() {
      try {
        let error = ''
        this.rules.forEach((fn) => {
          error = fn(this.currentValue)
          if (typeof error === 'string') {
            throw new Error(error)
          }

          this.isValid = true
          this.errorMessage = ''
        })
      } catch (e) {
        this.isValid = false
        this.errorMessage = e.message
      }

      return this.isValid
    },
  },
  watch: {
    currentValue() {
      if (this.rules.length) {
        this.validate()
      }
    },
  },
}
</script>
