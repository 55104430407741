import api from './api.service'

export default {
  all() {
    return api.index('positions')
  },

  create(data) {
    return api.store('positions', data)
  },

  get(positionId) {
    return api.show('positions', positionId)
  },

  update(positionId, data) {
    return api.update('positions', positionId, data)
  },

  delete(positionId) {
    return api.destroy('positions', positionId)
  },
}
