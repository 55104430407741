import api from './api.service'

export default {
  current() {
    return api.show('users', 'current')
  },

  all() {
    return api.index('users')
  },

  managers() {
    return api.show('users', 'managers')
  },

  workload(startDate, endDate) {
    return api.show('users', 'workload', {startDate, endDate})
  },

  create(data) {
    return api.store('users', data)
  },

  get(userId) {
    return api.show('users', userId)
  },

  update(userId, data) {
    return api.update('users', userId, data)
  },

  delete(userId) {
    return api.destroy('users', userId)
  },
}
