<template>
  <BmIcon v-bind="$props" v-slot:default="{fillClass}">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7306 0.200932C10.2544 0.0554179 9.74559 0.055419 9.26939 0.200932L0.884702 2.7631C0.359048 2.92373 0 3.40888 0 3.95853V14.4615C0 15.4981 0.639633 16.4271 1.60793 16.797L9.55396 19.8321C9.84119 19.9418 10.1588 19.9418 10.446 19.8321L18.392 16.797C19.3603 16.4271 20 15.4981 20 14.4615V3.95853C20 3.40888 19.6409 2.92373 19.1153 2.7631L10.7306 0.200932ZM9.99999 2.59179L14.4726 3.95853L9.99999 5.32527L5.52734 3.95853L9.99999 2.59179ZM8.74999 7.55742V16.8489L2.5 14.4615V5.64756L8.74999 7.55742Z"
      :class="fillClass"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconCube',
  props: {
    color: {
      type: String,
      default: 'black',
    },
    bgColor: {
      type: String,
      default: 'black',
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
  },
}
</script>
