export default [
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "default-layout" */ '@src/layouts/DefaultLayout'
      ),
    children: [
      {
        path: '',
        name: 'IndexPage',
        redirect: {name: 'ProjectsPage'},
      },
      {
        path: 'projects',
        name: 'ProjectsPage',
        component: () =>
          import(
            /* webpackChunkName: "projects-page" */ '@src/views/projects/ProjectsPage'
          ),
        meta: {
          title: 'Brave Manager - Projects',
        },
      },
      {
        path: 'projects/:id',
        name: 'ProjectPage',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "project-page" */ '@src/views/project/ProjectPage'
          ),
        meta: {
          title: 'Brave Manager',
        },
      },
      {
        path: 'users',
        name: 'EmployeesPage',
        component: () =>
          import(
            /* webpackChunkName: "users-page" */ '@/views/users/UsersPage'
          ),
        meta: {
          title: 'Brave Manager - Users',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () =>
      import(/* webpackChunkName: "login-page" */ '@src/views/LoginPage'),
    meta: {
      title: 'Brave Manager - Login',
    },
  },
  {
    path: '/:catchAll(.*)*',
    name: 'Error404',
    component: () =>
      import(/* webpackChunkName: "error-page" */ '@src/views/Error404.vue'),
    meta: {
      title: 'Brave Manager - 404',
    },
  },
]
