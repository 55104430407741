<template>
  <div :class="classes" :style="style"></div>
</template>

<script>
export default {
  name: 'BmSkeleton',
  props: {
    type: {
      type: String,
      default: 'text',
      validator: (value) => ['text', 'rect', 'circle'].includes(value),
    },
    width: {
      type: String,
      default: '50px',
    },
    height: {
      type: String,
      default: '10px',
    },
  },
  computed: {
    classes() {
      return ['skeleton', `skeleton_${this.type}`]
    },
    style() {
      return {
        width: this.width,
        height: this.height,
      }
    },
  },
}
</script>
