<template>
  <transition name="fade">
    <div class="modal" v-if="isOpen">
      <div class="modal__content">
        <div class="modal__header">
          <BmIconClose
            v-if="closable"
            class="modal__close"
            @click.native="close"
          />
          <slot name="header"></slot>
        </div>
        <div class="modal__body">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BmModal',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    isOpen: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      },
    },
  },
  methods: {
    close() {
      this.isOpen = false
    },
  },
}
</script>
