<template>
  <transition name="slide-to-left">
    <div v-if="shown" :class="classes">
      <div class="notify__icon" v-if="options.iconable">
        <slot name="icon">
          <BmIconCube />
        </slot>
      </div>

      <div class="notify__content">
        {{ content }}
      </div>
      <div v-if="options.closable" class="notify__close" @click="close"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BmNotify',
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    closable: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: 'bottom-right',
    },
    iconable: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 2000,
    },
  },
  data() {
    return {
      shown: false,
      content: this.text,
      options: {
        type: this.type,
        iconable: this.iconable,
        closable: this.closable,
        position: this.position,
        timeout: this.timeout,
      },
    }
  },
  computed: {
    classes() {
      return [
        'notify',
        this.shown && 'notify_shown',
        this.options.closable && 'notify_closable',
        this.options.iconable && 'notify_iconable',
        `notify_${this.options.type}`,
        `notify_${this.options.position}`,
      ]
    },
  },
  methods: {
    show(text, options = {}) {
      this.content = text
      Object.assign(this.options, options)

      this.shown = true

      setTimeout(() => {
        this.close()
      }, this.options.timeout)

      return this.close
    },
    close() {
      this.shown = false
    },
  },
}
</script>
