class ClickOutsideHandler {
  constructor() {
    this.handlers = new Map()
    this.isUsed = false
  }

  addListener(el, cb) {
    if (this.handlers.has(el)) {
      throw new Error(
        'The given DOM element already has a corresponding click outside listener !'
      )
    }

    if (!this.isUsed) {
      document.addEventListener('click', this)
      this.isUsed = true
    }

    const handler = (event) => {
      // here I check that click made outside the el and his children
      if (
        !(
          el === event.target ||
          event.target instanceof SVGElement || // workaround for embedded svg objects
          el.contains(event.target)
        )
      ) {
        cb(event)
      }
    }

    this.handlers.set(el, handler)
  }

  removeListener(el) {
    this.handlers.delete(el)

    if (this.handlers.size === 0) {
      document.removeEventListener('click', this)
      this.isUsed = false
    }
  }

  handleEvent(e) {
    this.handlers.forEach((handler) => handler(e))
  }

  static getOutsideClickHandler() {
    if (!this.outsideHandlerInstance)
      this.outsideHandlerInstance = new ClickOutsideHandler()

    return this.outsideHandlerInstance
  }
}

export default {
  bind(el, binding, vnode) {
    const callback = (event) => {
      // and if it did, call method provided in attribute value
      vnode.context[binding.expression](event)
    }

    ClickOutsideHandler.getOutsideClickHandler().addListener(el, callback)
  },

  unbind(el) {
    ClickOutsideHandler.getOutsideClickHandler().removeListener(el)
  },
}
