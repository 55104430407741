import clients from './clients.api'
import positions from './positions.api'
import projects from './projects.api'
import stages from './stages.api'
import units from './units.api'
import users from './users.api'
import notifications from './notifications.api'

export default {
  clients,
  positions,
  projects,
  stages,
  units,
  users,
  notifications,
}
