import api from './api.service'

export default {
  all(projectId) {
    return api.index(`projects/${projectId}/stages`)
  },

  create(projectId, data) {
    return api.store(`projects/${projectId}/stages`, data)
  },

  get(stageId) {
    return api.show(`stages`, stageId)
  },

  update(stageId, data) {
    return api.update('stages', stageId, data)
  },

  delete(stageId) {
    return api.destroy('stages', stageId)
  },

  syncPositions(stageId, data) {
    return api.store(`stages/${stageId}/positions`, data)
  },

  detachPosition(stageId, positionId) {
    return api.destroy(`stages/${stageId}/positions`, positionId)
  },

  syncUsers(stageId, data) {
    return api.store(`stages/${stageId}/users`, data)
  },

  detachUser(stageId, userId) {
    return api.destroy(`stages/${stageId}/users`, userId)
  },

  syncScheduledTimes(stageId, scheduledTimes) {
    return api.store(`stages/${stageId}/scheduled-times`, scheduledTimes)
  },

  updatePositions(stageId, positions) {
    return api.update(`stages/${stageId}/positions`, '', positions)
  },
}
