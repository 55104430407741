<template>
  <div
    v-click-outside="close"
    :class="selectClasses"
    class="select"
    @click="toggle"
  >
    <template v-if="option">
      <div :class="{select__label_active: !active}" class="select__label">
        <slot :value="option" name="active-label">
          <img
            v-if="option.avatar"
            alt=""
            class="select__img"
            :src="option.avatar"
          />
          <div class="select__value">{{ option.name }}</div>
        </slot>
        <BmIconTimes
          v-if="reset"
          class="select__reset"
          @click.native.stop="resetValue"
        />
      </div>
    </template>
    <template v-else>
      <div class="select__label">{{ label }}</div>
    </template>
    <input
      ref="input"
      v-model="filter"
      :class="inputClasses"
      class="select__input"
      type="text"
      @click.stop
    />
    <transition name="fade-slide-up">
      <div v-show="active" class="select__dropdown">
        <OverlayScrollbars class="select__scrollbar">
          <div class="select__options">
            <div
              v-for="option in filteredOptions"
              :key="option.id"
              :class="optionClasses(option)"
              class="select__option"
              @click="setValue(option)"
            >
              <slot :option="option" name="option">
                <img
                  v-if="option.avatar"
                  alt="avatar"
                  class="select__img"
                  :src="option.avatar"
                />
                <div class="select__value">{{ option.name }}</div>
              </slot>
            </div>
          </div>
        </OverlayScrollbars>
        <div v-if="!filteredOptions.length" class="select__empty-results">
          <img
            alt="search"
            class="select__search-icon"
            src="/assets/icons/search.svg"
          />
          Ничего не найдено
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import OverlayScrollbars from '../overlay-scrollbars/OverlayScrollbars'

export default {
  name: 'BmSelect',

  components: {
    OverlayScrollbars,
  },

  props: {
    options: [],
    option: {
      default: null,
    },
    label: '',
    reset: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      activating: false,
      active: false,
      filter: '',
    }
  },

  computed: {
    selectClasses() {
      return {
        select_active: this.active,
        select_selected: this.option,
      }
    },

    inputClasses() {
      return {select__input_active: this.active}
    },

    filteredOptions() {
      const regex = new RegExp(this.filter, 'i')
      return this.options.filter((option) => regex.test(option.name))
    },
  },

  methods: {
    close() {
      if (this.activating) {
        return
      }
      this.active = false
    },

    toggle() {
      this.active = !this.active
    },

    setValue(option) {
      this.option = option
    },

    resetValue() {
      this.option = null
    },

    optionClasses(option) {
      return {
        select__option_active: this.option && option.id === this.option.id,
      }
    },
  },

  watch: {
    active() {
      if (this.active) {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })

        this.activating = true
        setTimeout(() => (this.activating = false), 150)
      } else {
        this.filter = ''
      }
    },

    option(value) {
      this.$emit('update:option', value)
    },
  },
}
</script>
