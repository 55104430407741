import axios from 'axios'
import router from '@/router'
import {camelizeKeys, decamelizeKeys} from 'humps'

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  config.headers.Authorization = 'Bearer ' + token
  return config
})

axios.interceptors.response.use(null, (error) => {
  if (error.response.status === 401) {
    if (router.currentRoute.name !== 'LoginPage') {
      localStorage.removeItem('token')
      router.push({name: 'LoginPage'})
    }
  }
  return error
})

axios.interceptors.response.use((response) => {
  if (response.data) {
    response.data = camelizeKeys(response.data)
  }
  return response
})

axios.interceptors.request.use((config) => {
  const newConfig = {...config}
  if (config.params) {
    newConfig.params = decamelizeKeys(config.params)
  }
  if (config.data) {
    newConfig.data = decamelizeKeys(config.data)
  }
  return newConfig
})

export default {
  async index(resource) {
    const {data: response} = await axios.get(resource)
    return response?.data
  },

  async store(resource, data) {
    try {
      const {data: response} = await axios.post(resource, data)
      return response?.data
    } catch (e) {}
  },

  async show(resource, id, params) {
    const {data: response} = await axios.get(`${resource}/${id}`, {
      params: params,
    })
    return response?.data
  },

  async update(resource, id, data) {
    const {data: response} = await axios.put(`${resource}/${id}`, data)
    return response?.data
  },

  async destroy(resource, id) {
    const {data: response} = await axios.delete(`${resource}/${id}`)
    return response?.data
  },
}
