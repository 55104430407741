<template>
  <div
    v-on="$listeners"
    class="toggle"
    :class="{toggle_active: currentValue}"
    @click="toggle"
  ></div>
</template>

<script>
export default {
  name: 'BmToggle',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    toggle() {
      this.currentValue = !this.currentValue
    },
  },
}
</script>
