<template>
  <BmIcon v-bind="$props" #default="{fillClass}">
    <rect x="7" width="2" height="16" rx="1" :class="fillClass" />
    <rect
      x="16"
      y="7"
      width="2"
      height="16"
      rx="1"
      transform="rotate(90 16 7)"
      :class="fillClass"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconAddBtn',
  props: {
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    bgColor: {
      type: String,
      default: 'white',
    },
  },
}
</script>
