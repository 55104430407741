import Vue from 'vue'
import VTooltip from 'v-tooltip'
import _ from 'lodash'

import App from './App.vue'
import router from './router'
import store from './store'

import '@src/scss/app.scss'

import ClickOutside from '@src/directives/click-outside'

import BmIcon from './components/_shared/BmIcon'
import BmIconAdd from './components/_shared/icons/BmIconAdd'
import BmIconCheck from './components/_shared/icons/BmIconCheck'
import BmIconLogo from './components/_shared/icons/BmIconLogo'
import BmIconAlert from './components/_shared/icons/BmIconAlert'
import BmIconGoogle from './components/_shared/icons/BmIconGoogle'
import BmIconClose from './components/_shared/icons/BmIconClose'
import BmIconMore from './components/_shared/icons/BmIconMore'
import BmIconTrash from './components/_shared/icons/BmIconTrash'
import BmIconChevronLeft from './components/_shared/icons/BmIconChevronLeft'
import BmIconChevronRight from './components/_shared/icons/BmIconChevronRight'
import BmIconTimes from './components/_shared/icons/BmIconTimes'
import BmIconArrowLeft from './components/_shared/icons/BmIconArrowLeft'
import BmIconCalendar from './components/_shared/icons/BmIconCalendar'
import BmIconPencil from './components/_shared/icons/BmIconPencil'
import BmIconRuble from './components/_shared/icons/BmIconRuble'
import BmIconSearch from './components/_shared/icons/BmIconSearch'
import BmIconAddBtn from './components/_shared/icons/BmIconAddBtn'
import BmIconCube from './components/_shared/icons/BmIconCube'

import BmButton from './components/_shared/BmButton'
import BmInput from './components/_shared/BmInput'
import BmAvatar from './components/_shared/BmAvatar'
import BmModal from './components/_shared/BmModal'
import BmSelect from './components/_shared/BmSelect'
import BmTabs from './components/_shared/BmTabs'
import BmSkeleton from './components/_shared/BmSkeleton'
import BmSticky from './components/_shared/BmSticky'
import BmBadge from './components/_shared/BmBadge'
import BmToggle from './components/_shared/BmToggle'
import BmDate from './components/_shared/BmDate'
import BmNotify from './components/_shared/BmNotify'

import Moment from 'moment'
import {extendMoment} from 'moment-range'

Vue.prototype.$moment = extendMoment(Moment)
Vue.prototype.$moment.locale('ru')

Object.defineProperty(Vue.prototype, 'lodash', {value: _})

Vue.component('BmIcon', BmIcon)
Vue.component('BmIconAdd', BmIconAdd)
Vue.component('BmIconCheck', BmIconCheck)
Vue.component('BmIconLogo', BmIconLogo)
Vue.component('BmIconGoogle', BmIconGoogle)
Vue.component('BmIconAlert', BmIconAlert)
Vue.component('BmIconClose', BmIconClose)
Vue.component('BmIconMore', BmIconMore)
Vue.component('BmIconTrash', BmIconTrash)
Vue.component('BmIconChevronLeft', BmIconChevronLeft)
Vue.component('BmIconChevronRight', BmIconChevronRight)
Vue.component('BmIconTimes', BmIconTimes)
Vue.component('BmIconArrowLeft', BmIconArrowLeft)
Vue.component('BmIconPencil', BmIconPencil)
Vue.component('BmIconCalendar', BmIconCalendar)
Vue.component('BmIconRuble', BmIconRuble)
Vue.component('BmIconSearch', BmIconSearch)
Vue.component('BmIconAddBtn', BmIconAddBtn)
Vue.component('BmIconCube', BmIconCube)

Vue.component('BmButton', BmButton)
Vue.component('BmInput', BmInput)
Vue.component('BmAvatar', BmAvatar)
Vue.component('BmModal', BmModal)
Vue.component('BmSelect', BmSelect)
Vue.component('BmTabs', BmTabs)
Vue.component('BmSkeleton', BmSkeleton)
Vue.component('BmSticky', BmSticky)
Vue.component('BmBadge', BmBadge)
Vue.component('BmToggle', BmToggle)
Vue.component('BmDate', BmDate)
Vue.component('BmNotify', BmNotify)

Vue.use(VTooltip)
Vue.directive('click-outside', ClickOutside)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
