import api from './api.service'

export default {
  all() {
    return api.index('clients')
  },

  create(data) {
    return api.store('clients', data)
  },

  get(positionId) {
    return api.show('clients', positionId)
  },

  update(positionId, data) {
    return api.update('clients', positionId, data)
  },

  delete(positionId) {
    return api.destroy('clients', positionId)
  },
}
