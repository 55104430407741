<template>
  <BmIcon v-bind="$props" v-slot:default="{fillClass}">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44771 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44772 12 6 12C6.55229 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z"
      :class="fillClass"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconAdd',
  props: {
    color: {
      type: String,
      default: 'grey-dark',
    },
    bgColor: {
      type: String,
      default: 'grey-dark',
    },
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
  },
}
</script>
