<template>
  <BmIcon v-bind="$props" v-slot:default="{strokeClass}">
    <path
      :class="strokeClass"
      d="M11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7Z"
      stroke-width="2"
    />
    <path
      :class="strokeClass"
      d="M13 13L10 10"
      stroke-width="2"
      stroke-linecap="round"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconSearch',
  props: {
    color: {
      type: String,
      default: 'grey-light',
    },
    bgColor: {
      type: String,
      default: 'grey-light',
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
  },
}
</script>
