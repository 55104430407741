<template>
  <BmIcon v-bind="$props">
    <circle cx="10" cy="10" r="8" stroke="#E30613" stroke-width="2" />
    <rect
      x="8.75"
      y="5"
      width="2.5"
      height="6.24999"
      rx="1.25"
      fill="#E30613"
    />
    <rect x="8.75" y="12.5" width="2.5" height="2.5" rx="1.25" fill="#E30613" />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconAlert',
  props: {
    color: {
      type: String,
      default: 'red',
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
  },
}
</script>
