<template>
  <BmIcon v-bind="$props" v-slot:default="{strokeClass}">
    <path
      d="M5 5L11 11M11 5L5 11"
      :class="strokeClass"
      stroke-width="2"
      stroke-linecap="round"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconTimes',
  props: {
    color: {
      type: String,
      default: 'grey-light',
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
  },
  computed: {
    classes() {
      return [this.color ? `text-${this.color}` : '']
    },
  },
}
</script>
