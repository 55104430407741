import api from '@src/api'

export default {
  async fetchUnits({commit}) {
    try {
      const units = await api.units.all()
      commit('fetchUnits', units)
    } catch (e) {
      throw e
    } finally {
    }
  },
}
