<template>
  <div
    class="wrapper-date-picker"
    :class="{
      [`wrapper-date-picker_${size}`]: size,
      'wrapper-date-picker_secret': secret,
      'wrapper-date-picker_clearable': clearable,
      'wrapper-date-picker_stacked': focused || hasPeriodPart,
    }"
  >
    <div class="picker-placeholder picker-placeholder_stacked">Период</div>

    <div v-if="clearable && hasPeriodPart" class="picker-clear" @click="clear">
      <BmIconTimes />
    </div>

    <date-range-picker
      v-model="currentDateRange"
      ref="picker"
      class="date-picker"
      :locale-data="localeData"
      :ranges="false"
      @toggle="focused = !focused"
    />
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'BmDate',

  components: {
    DateRangePicker,
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },

    size: {
      type: String,
      default: '',
    },

    secret: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localeData: {
        direction: 'ltr',
        format: 'dd.mm.yyyy',
        separator: ' — ',
        applyLabel: 'Подтвердить',
        cancelLabel: 'Закрыть',
        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',
        ],
        firstDay: 1,
      },

      currentDateRange: {
        startDate: this.value.startDate || null,
        endDate: this.value.endDate || null,
      },

      focused: false,
    }
  },

  computed: {
    hasPeriodPart() {
      return this.currentDateRange.startDate || this.currentDateRange.endDate
    },
  },

  methods: {
    clear() {
      this.currentDateRange = {
        startDate: null,
        endDate: null,
      }
    },
  },

  watch: {
    currentDateRange: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
