<template>
  <BmIcon v-bind="$props" #default="{strokeClass}">
    <path
      d="M6 12L2 8M2 8L6 4M2 8L14 8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      :class="strokeClass"
    />
  </BmIcon>
</template>

<script>
export default {
  name: 'BmIconArrowLeft',
  props: {
    color: {
      type: String,
      default: 'grey-dark',
    },
    bgColor: {
      type: String,
      default: 'grey-dark',
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
  },
}
</script>
