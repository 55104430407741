export default {
  setProjects(state, projects) {
    state.allProjects = projects
  },
  setWorkload(state, workload) {
    state.workload = workload
  },
  setFilterDays(state, filterDays) {
    state.filterDays = filterDays
  },
  setFilterUser(state, filterUser) {
    state.filterUser = filterUser
  },
  setCurrentTabKey(state, key) {
    state.currentTabKey = key
  },
}
